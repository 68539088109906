.order-details {
    padding: 45px;

    &__header {
        padding-bottom: 25px;
        border-bottom: 1px solid rgba($c-black, 0.1);
    }

    &__status {
        padding: 20px 0;

        &--item {
            display: flex;
            align-items: center;

            span {
                margin-right: 40px;
            }
        }
    }

    &__cancel-status {
        display: flex;
        align-items: center;
    }

    &__badge {
        width: 20px;
        height: 20px;
        color: $c-white;
        margin-right: 5px;
        border-radius: 50px;
        background-color: $c-danger;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__status-list {
        padding: 0;
        margin: 10px 0 0;
        display: flex;
    }

    &__status-action {
        position: relative;
        margin: 0 15px 0 0;
        padding: 8px 25px;
        border-radius: 100px;
        border: 1px solid rgba($c-black, 0.2);
        list-style-type: none;
        cursor: pointer;

        &:hover {
            border: 1px solid $c-dark-gold;
            background-color: $c-dark-gold;

            p {
                color: $c-white;
            }
        }

        &--active {
            border: 1px solid $c-dark-gold;
            background-color: $c-dark-gold;

            p {
                color: $c-white;
            }

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: -5px;
                right: -10px;
                height: 25px;
                width: 25px;
                border-radius: 50px;
                background-color: $c-valid;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 18;
                background-image: url(../../../assets/icons/checked.svg);
            }
        }
    }

    &__content {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__item {
        margin: 1px 0;
        padding: 20px;
        background-color: $c-white;
        cursor: pointer;
        display: flex;
        justify-content: space-between;

        &--tracking-no {
            margin-top: 20px;
            margin-bottom: 15px;
            cursor: auto;

            .order-details__item-wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .text-input {
                    width: 100%;

                    &__container {
                        margin: 0;

                        input {
                            height: 35px;
                            padding: 0;
                            border: 0;
                        }
                    }
                }
            }
        }
    }

    &__item-wrapper {
        display: flex;
        align-items: center;

        &--total {
            justify-content: flex-end;
            align-items: flex-end;
            text-align: right;
        }
    }

    &__item-product {
        margin-left: 10px;
    }

    &__total {
        padding: 20px;
        display: flex;
        justify-content: space-between;

        &--white {
            padding: 10px 20px;
            justify-content: flex-end;
            background-color: $c-white;
        }
    }

    &__total-price {
        p {
            text-align: right;
        }
    }

    &__button-wrapper {
        margin-top: 25px;
    }

    &__cancel-card {
        padding: 40px 60px;
        width: 100%;
        min-width: 450px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        form {
            width: 100%;

            .dropdown {
                p {
                    margin: 5px 0;
                    text-align: left;
                }
            }

            .textarea__container {
                width: 100%;

                textarea {
                    width: 100%;
                    border: 0;
                    background-color: $c-input-bg;
                }
            }
        }

        p {
            margin: 5px 0;
        }

        .order-details__button-wrapper {
            display: flex;
            justify-content: center;

            button {
                width: 125px;
                margin: 0 5px;
            }
        }
    }

    &__cancel-dropdown {
        background-color: $c-input-bg;
        padding-top: 10px;

        p {
            padding: 0 14px;
        }

        .dropdown {
            
            p  {
                margin: 0 !important;
            }

            &__field {
                padding-top: 0;
                height: 100%;
            }
        }
    }
}
