.pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;

    &__item {
        border-right: 2px solid #D2D2D2;
        padding: 0 12px;
        cursor: pointer;

        &:last-child {
            border: 0;
        }

        &--active {
            text-decoration: underline;
            color: $c-black;
            font-weight: 500;
        }
    }

    .no-border {
        border: 0;
    }

    button {
        display: flex;
        align-items: center;
        border: none;
        background: none;
        cursor: pointer;
    }
}