.dashboard {
    padding: 45px;

    &__shop {
        margin-bottom: 25px;
    }

    &__profile {
        display: flex;
        margin-bottom: 25px;

        &--text {
            margin-left: 20px;
        }
    }

    &__stats {

        &--wrapper {
            display: flex;
            width: 100%;
        }

        &--card {
            padding: 35px 40px;
            background: linear-gradient(165.46deg, #0085FF 20.59%, #01B3EB 74.57%);
            border-radius: 8px;
            text-align: center;
            margin-right: 20px;
            flex: 1;
            max-width: 300px;

            .superscript__text {
                text-align: left;

                &--invisible {
                    opacity: 0;
                }
            }
        }
    }

    &__revenue {
        
        &--container {
            padding: 24px;
            background-color: $c-white;
            border-radius: 6px;
            margin-top: 14px;
            max-width: 940px;
        }

        &--sort {
            display: flex;
            justify-content: space-between;
            height: 42px;
        }

        &--date {
            display: flex;
            height: 100%;
            background-color: #F7F7F7;

            input[type="date"] {
                position: relative;
                border: 0;
                text-align: center;
                background-color: #F7F7F7;
            }

            input[type="date"]::-webkit-inner-spin-button,
            input[type="date"]::-webkit-calendar-picker-indicator {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 0;
                cursor: pointer;
                opacity: 0;
            }

            .dash {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 10px;
            }

            .calendar-icon {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 25px;
                margin-right: 13px;
            }
        }

        &--period {
            display: flex;
            height: 100%;
        }

        &--graph {
            margin-top: 30px;
        }
    }

    &__orders {
        margin-top: 25px;

        &--text {
            display: flex;
            justify-content: space-between;
            &:last-child {
                cursor: pointer;
            }
        }
    }
}
