.account-reset-pwd {

    &__headline {
        margin-bottom: 25px;
    }

    &__card {
        padding: 45px;
    }

    &__input-wrapper {
        margin-top: 30px;
        width: 50%;

        &:first-child {
            margin: 0;
        }
    }

    &__button-wrapper {
        margin-top: 35px;
    }
}
