.live-stream-select {
    position: relative;
    padding: 45px 45px 100px;

    &__wrapper {
    }

    &__search-wrapper {
    }

    &__button-wrapper {
        position: fixed;
        width: 250px;
        bottom: 85px;
        margin-top: 30px;
        margin-top: 30px;
    }
}

.select-product__popup {
    min-width: 600px;
    max-height: 600px;
    
    &--header {
        margin-bottom: 25px;
        text-align: left;
    }

    &--search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;

        .search-bar {
            margin-bottom: 0;
            min-width: 450px;
        }
    }

    &--body {
        max-height: 400px;
        overflow: auto;
    }

    &--wrapper {
        padding: 20px 0;
    }

    .select-product__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;

        &--left {

            .circle-avatar {
                margin-right: 20px;
            }
        }

        &--right {
            text-align: right;

            svg {
                margin-left: 20px;
            }
        }
    }

    &--footer {
        margin-top: 25px;
    }
}
