@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import "normalize.css";
@import "react-responsive-modal/styles.css";
@import "styles/colors.scss";
@import "styles/m-touch.scss";
@import "react-datepicker/dist/react-datepicker.css";

/* Components */
@import "components/nav-bar/nav-bar.scss";
@import "components/side-nav/side-nav.scss";
@import "components/button/button.scss";
@import "components/inputs/text-input/text-input.scss";
@import "components/inputs/textarea/textarea.scss";
@import "components/error/error.scss";
@import "components/search-bar/search-bar.scss";
@import "components/inputs/dropdown/dropdown.scss";
@import "components/inputs/date-time-picker/date-time-picker.scss";
@import "components/product-card/product-card.scss";
@import "components/quantity-picker/quantity-picker.scss";
@import "components/footer/footer.scss";
@import "components/layout/layout.scss";
@import "components/breadcrumb/breadcrumb.scss";
@import "components/circle-avatar/circle-avatar.scss";
@import "components/rating-picker/rating-picker.scss";
@import "components/share-button/share-button.scss";
@import "components/business-information-board/business-information-board.scss";
@import "components/short-duration-modal/short-duration-modal.scss";
@import "components/tabs/tabs.scss";
@import "components/tables/product-table/product-table.scss";
@import "components/tables/order-table/order-table.scss";
@import "components/tables/product-review-table/product-review-table.scss";
@import "components/tables/live-stream-table/live-stream-table.scss";
@import "components/popup/popup.scss";
@import "components/time-period-selector/time-period-selector.scss";
@import "components/filter/filter.scss";
@import "components/pagination/pagination.scss";
@import "components/headerSort/headerSort.scss";
@import "components/product-stock/product-stock.scss";
@import "components/alert/alert.scss";
@import "components/empty-state/empty-state.scss";

/* Pages */
@import "pages/auth/login/login-page.scss";
@import "pages/auth/forgot-password/forgot-password-page.scss";
@import "pages/auth/forgot-password-verification/forgot-password-verification-page.scss";
@import "pages/auth/reset-password/reset-password-page.scss";
@import "pages/auth/register/register-page.scss";
@import "pages/auth/vefication-account/vefication-account-page.scss";
@import "pages/dashboard/dashboard-page.scss";
@import "pages/accounts/account/account-page.scss";
@import "pages/accounts/account-settings/account-settings-page.scss";
@import "pages/accounts/account-profile/account-profile-page.scss";
@import "pages/accounts/account-business-information/account-business-information-page.scss";
@import "pages/accounts/account-reset-password/account-reset-password-page.scss";
@import "pages/accounts/account-shipping/account-shipping-page.scss";
@import "pages/accounts/account-product-review/account-product-review-page.scss";
@import "pages/live-stream/live-stream-create/live-stream-create-page.scss";
@import "pages/live-stream/live-stream-select-product/live-stream-select-product-page.scss";
@import "pages/live-stream/live-stream-lists/live-stream-lists-page.scss";
@import "pages/orders/orders/orders-page.scss";
@import "pages/orders/order-details/order-details-page.scss";
@import "pages/orders/order-product-details/order-product-details-page.scss";
@import "pages/products/products/products-page.scss";
@import "pages/products/product-create/product-create-page.scss";
@import "pages/ad-application/ad-application-page.scss";
@import "pages/common/not-found/not-found-page.scss";
@import "pages/common/mobile-user/mobile-user-page.scss";
@import "pages/common/policy/policy-page.scss";
@import "pages/common/terms/terms-page.scss";
@import "pages/common/about-us/about-us-page.scss";
@import "pages/common/help/help-page.scss";
@import "pages/common/faq/faq-page.scss";

* {
    box-sizing: border-box;
}

html {
    padding: 0;
    margin: 0;
    height: 100%;

    body {
        padding: 0;
        margin: 0;
        min-height: 100%;
        font-family: $s-font-family;
        white-space: pre-wrap;
        background-color: $c-layout-bg;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input:disabled, textarea:disabled {
    background: #E6E6E6 !important;
}

.Dropdown-disabled {
    background: #E6E6E6;
}

.react-responsive-modal-modal {
    &:focus-visible {
        outline: none !important;
    }
}
