.textarea {
    &__container {
        position: relative;
        margin-bottom: 15px;
    }

    &__label {
        display: block;
        margin-bottom: 5px;
    }

    &__field {
        width: 100%;
        max-width: 400px;
        min-width: 100%;
        height: 200px;
        min-height: 200px;
        max-height: 200px;
        padding: 16px;
        font-size: 16px;
        font-weight: 400;
        border-radius: 5px;
        border: 0;
        font-family: $s-font-family;
        background-color: $c-input-bg;

        &:focus {
            outline: none;
        }

        &--max {
            max-width: 100%;
        }

        &--outline {
            background-color: $c-white;
            border: 1px solid rgba($c-black, 0.1);
        }

        &::placeholder {
            opacity: 0.5;
            color: $c-input-placeholder;
            font-family: $s-font-family;
        }
    }
}
