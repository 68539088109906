.text-input {
    &__container {
        position: relative;
        margin-bottom: 15px;
    }

    &__label {
        display: block;
        margin-bottom: 5px;
    }

    &__eyes {
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__field {
        width: 100%;
        height: 58px;
        max-width: 400px;
        padding: 16px;
        font-size: 16px;
        font-weight: 300;
        border-radius: 5px;
        border: 0;
        background-color: $c-input-bg;

        &:focus {
            outline: none;
        }

        &--max {
            max-width: 100%;
        }

        &--outline {
            background-color: $c-white;
            border: 1px solid rgba($c-black, 0.1);
        }

        &--password {
            padding-right: 47px;
        }

        &::placeholder {
            opacity: 0.5;
            color: $c-input-placeholder;
        }
    }
}
