.header__sort {
    display: flex;
    flex-direction: column;

    & > * {
        max-height: 12px;
        color: #A1A1A1;
    }

    &--white {
        color: $c-white;
    }
}