.account-shipping {

    &__headline {
        margin-bottom: 25px;
    }

    &__card {
        padding: 45px;
    }

    &__header {
        display: flex;
        align-items: center;

        p {
            margin-left: 15px;
        }
    }

    &__input-wrapper {
        margin-top: 30px;
        width: 40%;
    }

    &__button-wrapper {
        margin-top: 35px;
    }
}
