.time-period {
    border: 1px solid #E2E2E2;
    background-color: $c-white;
    border-radius: 0;
    color: #999999;
    padding: 12px;
    cursor: pointer;

    &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px; 
    }

    &__selected {
        background-color: #444444;
        color: $c-white !important;
    }
}

