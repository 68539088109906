.terms {
    .container {
        flex-direction: row;
        align-items: stretch;
    }

    &__headline {
        margin: 35px 0;
    }

    &__card {
        width: 100%;
        min-height: 550px;
        margin: 35px;
        padding: 35px;
    }
}
