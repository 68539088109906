.side-nav {
    padding: 11px 0;
    width: 325px;
    min-width: 100px;
    background-color: $c-primary;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--shrink {
        animation: shrinkNav 0.5s ease forwards;
    }
    &--expand {
        animation: expandNav 0.5s ease forwards;
    }

    &__logo {
        margin: 25px 0;
        width: 65px;

        img {
            width: 100%;
        }
    }

    &__action {
        width: 100%;
        min-height: 65px;
        padding-right: 34px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        svg {
            color: $c-dark-gold;
        }
    }

    &__lists {
        width: 100%;
        padding: 0;

        &--shrink {
            width: 100%;

            li {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                p {
                    display: none;
                }

                img {
                    margin-right: 0;
                }
            }
        }
        &--expand {
            li {
                p {
                    animation: showText 2s ease forwards;
                }
            }
        }
    }

    &__link {
        width: 100%;
        display: block;
        text-decoration: none;
    }

    &__item {
        width: 100%;
        padding: 20px 35px;
        list-style-type: none;
        display: flex;
        align-items: flex-end;

        img {
            margin-right: 10px;
        }

        p {
            color: #a1a1a1;
            white-space: nowrap;
        }

        &--active {
            color: $c-white;
            background-color: rgba($c-white, 0.1);

            p {
                color: $c-white;
            }

            img {
                filter: brightness(0) invert(1);
            }
        }

        &:hover {
            background-color: rgba($c-white, 0.1);

            p {
                color: $c-white;
            }

            img {
                filter: brightness(0) invert(1);
            }
        }
    }
}

@keyframes shrinkNav {
    0% {
        width: 325px;
    }
    100% {
        width: 95px;
    }
}

@keyframes expandNav {
    0% {
        width: 95px;
    }
    100% {
        width: 325px;
    }
}

@keyframes showText {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
