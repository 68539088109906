.circle-avatar {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba($c-black, 0.2);
    border-radius: 1000px;
    overflow: hidden;
}
