.product-review-table {
    width: 100%;
    margin-top: 10px;
    min-width: 860px;
    border: 1px solid rgba($c-layout-bg, 0.5);

    &__header {
        display: flex;
        justify-content: space-between;
    }
    
    thead {
        background-color: $c-secondary;

        th {
            padding: 20px;
            border: 1px solid rgba($c-layout-bg, 0.5);
            text-align: left;

            &:first-child {
                width: 70px;
                border-right: 0;
                padding-right: 5px;
            }

            &:nth-child(2) {
                border-left: 0;
                padding-left: 5px;
            }

            &:last-child {
                width: 50px;
            }
        }
    }

    tbody {
        background-color: $c-white;

        tr {
            cursor: pointer;
        }

        td {
            padding: 20px;
            border: 1px solid rgba($c-layout-bg, 0.5);
            text-align: left;

            &:first-child {
                width: 70px;
                border-right: 0;
                padding-right: 5px;
            }

            &:nth-child(2) {
                border-left: 0;
                padding-left: 5px;
                text-align: left;
            }

            &:last-child {
                width: 50px;
            }
        }
    }

    &__rating {
        display: flex;

        p {
            flex: 0 0 80px;
        }
    }

    &__navigation {
        display: flex;
        justify-content: center;
        align-items: center;
        
        p {
            margin-right: 10px;
            background-color: $c-valid;
            border-radius: 100px;
            padding: 5px 10px;
            color: $c-white;
        }
    }
}
