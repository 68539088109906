.account-buss-info {
    
    &__card {
        padding: 45px;
    }

    hr {
        width: 100%;
        height: 1px;
        margin-top: 35px;
        background-color: #E6E6E6;
        border: 0;
    }
    
    &__headline {
        margin-bottom: 25px;
    }

    &__input-wrapper {
        margin-top: 30px;
        width: 50%;
    }

    &__upload-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 50%;
        margin-bottom: 20px;
        
        .account-buss-info__input-wrapper {
            width: calc(50% - 10px);
            margin: 10px 10px 10px 0;
        }

        p {
            margin-top: 5px;
        }
    }

    &__upload-button {
        padding: 15px 20px;
        width: 100%;
        border-radius: 5px;
        color: rgba($c-black, 0.3);
        background-color: $c-white;
        border: 1px dashed rgba($c-black, 0.2);
        cursor: pointer;
        display: flex;
        align-items: center;

        &--uploaded {
            border: 0;
            border-radius: 0;
            box-shadow: $s-shadow;
            background-color: $c-white;
            border-bottom: 3px solid $c-valid;
            span {
                color: $c-black;
            }
        }

        span {
            width: 90%;
            padding-left: 5px;
            display: block;
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .account-buss-info__icon-close {
            width: 10%;
            color: $c-black;
        }

        .account-buss-info__icon-add {
            width: 10%;
        }
    }

    &__icon-upload {
        width: 25px;
        height: 25px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(../../../assets/icons/icon-uploaded-file.svg);
    }

    &__button-wrapper {
        margin-top: 35px;
    }
}

.swift-code__tooltip {
    max-width: 425px;
    padding: 25px 10px;

    &--icon {
        display: flex;
        align-items: center;

        svg {
            margin-left: 15px;
            cursor: pointer;
            color: $c-secondary;
        }
    }

    &--wrapper {
        display: flex;
        align-items: center;
        background: $c-white;
        padding: 15px;

        > * {
            margin-right: 20px;
        }
    }
}