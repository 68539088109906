.adApplication {
    padding: 45px;

    &__container {
        background: $c-white;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 45px;
        max-width: 80%;
    }

    &__header {
        max-width: 50%;
        margin-bottom: 28px;
    }

    &__body {
        width: 60%;
        padding: 60px 50px;

        &-item {
            display: flex;
            align-items: center;
            margin: 40px 0;

            svg {
                margin-right: 20px;
            }
        }
    }

    &__form {
        margin-top: 35px;

        &--actions {
            display: flex;
            justify-content: space-between;
            margin-top: 5px;

            .react-datepicker__input-container {

                input {
                    background: $c-white;
                    border: 1px solid #E6E6E6;
                    border-radius: 4px;
                    padding: 13px 19px;
                    width: 400px;
                }
            }
        }

        &--button {
            display: flex;
            justify-content: space-between;
            margin-top: 13px;
        }
    }

    &__desktop {
        margin-top: 35px;

        &--title {
            display: flex;

            p {
                margin-right: 10px;
            }
        }

        &--container {
            border: 1px dashed #BBBBBB;
            box-sizing: border-box;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 50px;
            margin-top: 10px;
        }

        &--margin {
            margin: 14px 0;
        }
    }

    &__mobile {
        margin-top: 35px;

        &--title {
            display: flex;
            margin-bottom: 10px;

            p {
                margin-right: 10px;
            }
        }

        &--wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }

        &--container {
            border: 1px dashed #BBBBBB;
            box-sizing: border-box;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 50px;
            margin-top: 20px;
            width: 50%;
        }

        &--margin {
            margin: 14px 0;
        }
    }

    &__image {
        width: 100%;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__popup {
        display: flex;
        justify-content: center;

        &--msg {
            width: 70%;
            
            p {
                &:first-child {
                    margin-top: 20px;
                    margin-bottom: 50px;
                }
            }
        }
    }
}