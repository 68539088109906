.product_stock {
    &__action {
        display: flex;
        align-items: center;

        &--add {
            margin-left: 10px;
            width: 24px;
            height: 24px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 4px;
        }

        &--warning {
            background-color: $c-warning;
        }

        &--error {
            background-color: $c-danger;
        }

    }

    &__popup {

        &--wrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;

            p {
                margin-right: 40px;
            }
        }

        &--header {
            display: flex;
            align-items: center;
            
            p {
                margin-left: 20px;
            }
        }

        hr {
            // position: relative;
            // border: 0;
            // height: 2px;
            // overflow: unset;
            margin: 35px 0;

            // &::before {
            //     content: '';
            //     position: absolute;
            //     bottom: 1px;
            //     left: -20%;
            //     min-width: 340px;
            //     height: 1px;
            //     background-color: rgba($c-black, 0.2);
            // }
        }

        &--body {
            max-height: 300px;
            overflow-y: auto;
        }

        &--action {
            display: flex;
            
            input {
                max-width: 110px;
                max-height: 34px;
                text-align: center;
            }

            span {
                width: 34px;
                height: 34px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 30px;
            }
        }

        &--minus {
            background-color: $c-secondary;
            margin-right: 12px;
        }

        &--add {
            background-color: $c-dark-gold;
            margin-left: 12px;
        }

        &--footer {
            margin-top: 50px;
            display: flex;
        }
    }
}