.short-duration-modal {
    &__card {
        width: 350px;
        height: 200px;
        padding: 20px;
        border-radius: 15px;
        background-color: rgba($c-black, 0.8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 999999;
    }

    &__msg {
        color: $c-white;
        text-align: center;
    }
}
