.signup {
    .container {
        flex-direction: row;
        align-items: stretch;
        text-align: center;
    }

    &__section {
        width: 100%;
        max-width: 400px;
        padding: 20px;

        img {
            width: 100%;
        }
    }

    &__divider {
        width: 1px;
        height: 90%;
        margin: 0 25px;
        background-color: $c-border;
    }

    &__header,
    &__terms,
    &__link {
        margin: 20px 0;
    }

    &__types {
        padding: 0;
        display: flex;
        justify-content: center;
    }

    &__type {
        padding: 0 20px;
        cursor: pointer;
        list-style-type: none;

        &--active {
            color: $c-dark-gold;
            font-weight: 500;
        }

        &:first-child {
            border-right: 1px solid $c-black;
        }
    }

    &__card {
        width: 100%;
        min-height: 640px;
        margin: 35px;
        padding: 35px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}
