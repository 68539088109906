.quantity-picker {
    display: flex;
    align-items: center;

    &__button {
        width: 35px;
        height: 35px;
        margin: 0 15px;
        color: $c-white;
        font-size: 18px;
        border-radius: 40px;
        background-color: $c-dark-gold;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &--outline {
            color: $c-dark-gold;
            border: 1px solid $c-dark-gold;
            background-color: $c-white;
        }

        &:disabled {
            opacity: 0.5;
            background-color: $c-disabled;
        }
    }

    &__input {
        padding: 5px;
        width: 80px;
        border-radius: 5px;
        text-align: center;
        outline: none;
        border: 1px solid rgba($c-black, 0.2);
    }
}
