.alert {
    position: fixed;
    top: 50px;
    left: 50%;
    margin-left: -50px;
    background: $c-white;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 20px 30px;
    min-width: 300px;
    opacity: 1;

    &-wrapper {
        display: flex; 

        p {
            margin-left: 30px;
        }
    }

    &-close {
        opacity: 0;
    }
}
