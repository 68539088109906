.live-stream-create {
    padding: 45px;

    &__wrapper {
        padding: 60px;
    }

    &__headline {
        margin-top: 20px;
    }

    &__input-wrapper {
        margin: 15px 0;
        padding: 20px 0;
        background-color: $c-white;
        width: 50%;

        &--fullw {
            width: 100%;
        }

        .date-time-picker {
            .date-time-picker__label {
                @extend .m-txt--grey;
            }
        }

        .text-input {
            label {
                @extend .m-txt--grey;
            }

            &__container {
                margin: 0;

                input {
                    height: 50px;
                    background-color: transparent;
                }
            }
        }
    }

    &__table-wrapper {

        .product-table {
            min-width: 100%;

            th, td {
                text-align: left;
            }

            th, td {

                &:nth-child(5) {
                    width: 20%;
                }
            }
        }
    }

    &__upload-button {
        padding: 0;
        margin-top: 10px;
        width: 165px;
        height: 165px;
        border-radius: 10px;
        color: rgba($c-black, 0.3);
        background-color: $c-white;
        border: 1px dashed rgba($c-black, 0.2);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &--product {
            width: 200px;
            height: 100px;
        }
    }

    &__uploaded-cover {
        width: 100%;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        border-radius: 10px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    &__add-product {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    &__add-action {
        display: flex;
    }

    &__add-icon {
        margin-right: 5px;
        color: $c-dark-gold;
    }

    &__button-wrapper {
        margin-top: 30px;
    }

}

.delete-stream__popup {

    &--msg {
        margin-top: 30px;
        margin-bottom: 40px;
    }

    &--footer {
        display: flex;
    }
}