.not-found {
    .container {
        flex-direction: row;
        align-items: stretch;
        text-align: center;
    }

    &__link {
        margin-top: 25px;
        width: 100%;
        max-width: 250px;
    }

    &__headline {
        margin: 35px 0;
    }

    &__image {
        width: 100%;
        max-width: 350px;
    }

    &__card {
        width: 100%;
        min-height: 550px;
        margin: 35px;
        padding: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}
