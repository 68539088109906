.account {
    padding: 45px;

    .container {
        flex-direction: column;
    }

    &__wrapper {
        max-width: 832px;
    }

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    &__name {
        margin-left: 25px;
        font-size: 28px;
        font-weight: 300;
        color: $c-black;
    }

    &__body {
        margin-top: 35px;
    }

    &__card {
        width: 188px;
        height: 188px;
        margin-top: 20px;
        margin-right: 20px;
        padding: 20px;
        border-radius: 8px;
        cursor: pointer;
        background-color: $c-white;
        border: 1px solid rgba($c-black, 0.2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &--text {
            display: flex;
            justify-content: space-between;
            margin-right: 20px;
        }

        img {
            margin-bottom: 20px;
        }
    }

    &__card-items {
        width: 100%;
        display: flex;
        align-items: center;
    }

    &__card-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 50px;
        }
    }

    &__icon {
        width: 70px;
        height: 70px;
        margin-bottom: 10px;
        background-position: center;
        background-repeat: no-repeat;
    }

    &__badge {
        position: absolute;
        top: -8px;
        right: -8px;
        width: 20px;
        height: 20px;
        font-size: 10px;
        color: $c-white;
        border-radius: 20px;
        background-color: $c-valid;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__footer {
        margin-top: 50px;
        margin-right: 20px;
        align-items: center;

        &--item {
            width: 100%;
            padding: 20px 30px;
            background-color: $c-white;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;
            cursor: pointer;

            .account__review {
                display: flex;

                span {
                    margin-left: 10px;
                    background-color: #54bf86;
                    border-radius: 100px;
                    padding: 5px 8px;
                    color: #ffffff;
                    font-size: 12px;
                }
            }
        }

        .account__icon {
            margin: 0;
        }
    }

    &__popup {
        display: flex;
        justify-content: flex-start;
        text-align: left;

        &--msg {
            
            > * {
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
