.order-product-details {
    padding: 45px;

    &__image {
        width: 300px;
        height: 200px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;
    }

    &__label {
        color: rgba($c-black, 0.2);
    }

    &__product {
        padding: 25px 0;

        &--border {
            border-bottom: 1px solid rgba($c-black, 0.1);
        }
    }

    &__item {
        margin: 1px 0;
        padding: 20px;
        background-color: $c-white;
    }

    &__item-wrapper {
        width: 20%;
        display: flex;
        flex-direction: column;
    }

    &__item-product {
        margin-left: 10px;
    }
}
