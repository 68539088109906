.products {
    padding: 45px;

    &__card-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        .search-bar {
            margin-bottom: 0;
            min-width: 500px;
        }
    }

    &__action-wrapper {
        display: flex;
        align-items: center;
    }

    &__add-action {
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    &__grid-action {
        cursor: pointer;
        margin-left: 20px;
    }

    &__create-icon {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    &__grid-icon {
        width: 25px;
        height: 25px;
    }

    &__disable {

        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 17px;
        }
    
        &-btn {
            position: relative;
            width: 57px;
            height: 30px;
            display: flex;
            align-items: center;
            cursor: pointer;
            border-radius: 50px;
            background-color: $c-layout-bg;
            border: 1px solid rgba($c-black, 0.2);
            margin-left: 20px;

            span {
                width: 22px;
                height: 22px;
                border-radius: 50%;
                background: $c-disabled;
            }
        }
    }

    &__popup {
        &--msg {
            margin: 20px 0;
        }

        &--btn {
            display: flex;
        }
    }

}
