.breadcrumb {
    padding: 20px 0;
    width: 100%;
    display: flex;
    align-items: center;

    &__link {
        font-size: 16px;
        font-weight: 400;
        color: $c-black;
        text-decoration: none;
        display: flex;
        align-items: center;
        cursor: pointer;

        &--active {
            color: $c-dark-gold;
        }
    }
}
