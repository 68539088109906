.account-profile {
    padding: 45px;

    &__card {
        padding: 45px;
        max-width: 600px;
        margin-top: 28px;

        span {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
            position: relative;

            p {
                margin-right: 8px;
            }

            .redDot {
                width: 6px;
                height: 6px;
                background-color: $c-danger;
                border-radius: 50%;
                position: absolute;
                top: 0;
                right: -8px;
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;

        p {
            margin-left: 15px;
        }
    }

    &__input-wrapper {
        margin-top: 30px;
        width: 80%;
    }

    &__button-wrapper {
        margin-top: 35px;
    }
}
