.product-card {
    width: calc(25% - 25px);
    min-width: 240px;
    max-height: 420px;
    margin: 10px;
    border-radius: 10px;
    background-color: $c-white;
    box-shadow: $s-shadow;
    overflow: hidden;
    cursor: pointer;

    &__image {
        width: 100%;
        height: 200px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__stock {
        margin-top: 10px;
        display: flex;
    }

    &__content {
        width: 100%;
        padding: 10px 10px 0;

        p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &--created-date {
            padding-top: 0;
            padding-bottom: 10px;
            text-align: right;
        }
    }
}
