.date-time-picker {
    border: 0;

    &__label {
        margin-bottom: 5px;
    }

    input,
    select {
        outline: none;
    }

    .react-datetime-picker--disabled {
        background-color: transparent;
    }

    .date-time-calendar {
        height: 35px;

        .react-datetime-picker {
            &__wrapper {
                border: 0;
            }

            &__inputGroup {
                &__divider {
                    color: rgba($c-black, 0.5);
                }

                &__input {
                    &:invalid {
                        background-color: transparent;
                    }
                }
            }

            &__calendar {
                font-size: 18px;

                .react-calendar {
                    padding: 20px;
                    border-radius: 10px;
                    box-shadow: $s-shadow;
                    border: 1px solid $c-border;

                    abbr[title] {
                        text-decoration: none;
                    }

                    &__navigation {
                        &__arrow {
                            margin: 2px;
                            border-radius: 5px;
                        }

                        &__label {
                            border-radius: 5px;
                        }
                    }

                    &__month-view {
                        &__weekdays {
                            &__weekday {
                                color: $c-dark-gold;
                            }
                        }
                    }

                    &__month-view {
                        &__days {
                            &__day {
                                &--weekend {
                                    color: $c-danger;
                                }
                            }
                        }
                    }

                    &__tile {
                        border: 2px solid $c-white;
                        border-radius: 5px;

                        &:disabled {
                            opacity: 0.5;
                            color: rgba($c-black, 0.5);
                            background-color: rgba($c-black, 0.1);

                            &:hover {
                                background-color: rgba($c-black, 0.1);
                            }
                        }

                        &:hover {
                            border-radius: 5px;
                            background-color: rgba($c-secondary, 0.1);
                        }

                        &--now {
                            color: $c-white;
                            border-radius: 5px;
                            background-color: $c-dark-gold;
                        }
                    }
                }
            }
        }
    }

    //New Date Picker
    &__wrapper {
        position: relative;
    }

    &__icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-50%, -50%); 
    }

    .react-datepicker__input-container {

        input {
            width: 100%;
            height: 50px;
            padding: 16px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            font-size: 16px;
        }
    }
}
