.account-product-review {
    padding: 45px;

    &__search {
        margin-top: 25px;
    }
}

.account-product-review-detail {
    padding: 45px 110px 45px 45px;
    
    &__product {
        display:  flex;
        margin-bottom: 20px;
        padding: 20px 30px 30px 30px;
        align-items: center;
        background: #FFFFFF;
        border: 1px solid #E6E6E6;

        & > * {
            flex: 1 1 0px;
        }

        & > :first-child {
            flex: 0 0 25%;
        }

        & > :last-child {
            flex: 0 0 40%;
        }

        &--title {
            margin-bottom: 20px;
        }
        
        &--wrapper {
            display: flex;

            p {
                margin-left: 10px;
            }
        }

        &--rating {
            display: flex;
    
            p {
                margin-right: 15px;
            }
        }
    }

    &__reviews {

        &--card {
            padding: 35px 40px 40px 40px;
            background: $c-white;
            border: 1px solid #E6E6E6;

        }

        &--date {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 5px;
        }

        &--wrapper {
            display: flex;
            
            & > :first-child {
                margin-right: 24px;
            }
            
            & > :last-child {
                flex: 0 0 80%;
            }
        }

        &--body {
            margin: 20px 0 20px 20px;

            p {
                margin: 20px 0 15px 0;   
            }
        }

        &--imageWrapper {
            display: flex;
        }

        &--image {
            width: 100px;
            margin-top: 15px;
            margin-right: 10px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        &--reply {
            display: flex;
            align-items: center;
            padding-left: 20px;
            width: 100%;
            
            input {
                width: 80%;
                height: 50px;
                padding: 14px 20px;
                border: 1px solid #E6E6E6;
                border-radius: 4px;
            }

            & > :last-child {
                margin-left: 15px;
            }
        }

        &--replied {
            background: #F6F6F6;
            border-radius: 4px;
            padding: 16px 24px;
            margin-left: 20px;
            margin-bottom: 20px;

            & > :first-child {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}