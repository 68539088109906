.live-stream {
    padding: 45px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        .search-bar {
            margin-bottom: 0;
            min-width: 500px;
        }
    }

    &__add-action {
        cursor: pointer;
        display: flex;
        float: right;
    }

    &__add-icon {
        margin-right: 5px;
        color: $c-dark-gold;
    }

    &__table {
        width: 100%;
        margin-top: 35px;
        text-align: center;

        thead {
            background-color: $c-secondary;

            th {
                padding: 20px;
                border: 1px solid rgba($c-layout-bg, 0.5);

                &:first-child {
                    width: 70px;
                    border-right: 0;
                    padding-right: 5px;
                }

                &:nth-child(2) {
                    border-left: 0;
                    padding-left: 5px;
                    text-align: left;
                }
            }
        }

        tbody {
            background-color: $c-white;

            td {
                padding: 20px;
                border: 1px solid rgba($c-layout-bg, 0.5);

                &:first-child {
                    width: 70px;
                    border-right: 0;
                    padding-right: 5px;
                }

                &:nth-child(2) {
                    border-left: 0;
                    padding-left: 5px;
                    text-align: left;
                }
            }
        }
    }
}
