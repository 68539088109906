.search-bar {
    margin-bottom: 35px;
    max-width: 500px;
    
    &__form {
        width: 100%;
    }

    &__wrapper {
        width: 100%;
        display: flex;
    }

    &__sortby {
        position: relative;
        padding: 0 15px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        white-space: nowrap;

        &:hover {
            .search-bar__sortby-list {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &__sortby-list {
        position: absolute;
        width: 200px;
        top: calc(100% - 20px);
        left: 0;
        right: 0;
        padding: 0;
        opacity: 0;
        visibility: hidden;
        background-color: $c-white;
        box-shadow: $s-shadow;
        z-index: 99999;
        transition: all 0.2s ease;
    }

    &__sortby-item {
        margin: 5px;
        padding: 15px;
        list-style-type: none;
        background-color: $c-white;
        z-index: 99999;

        &--active {
            background-color: $c-border;
        }

        &:hover {
            background-color: $c-border;
        }
    }

    &__sortby-icon {
        margin-left: 5px;
    }

    &__input {
        padding: 0 16px;
        width: 100%;
        max-width: 640px;
        background-color: $c-white;
        border: 1px solid rgba($c-black, 0.1);
        display: flex;
        align-items: center;
    }

    &__field {
        width: 100%;
        height: 58px;
        padding: 16px;
        font-size: 16px;
        font-weight: 300;
        border: 0;
        background-color: transparent;

        &:focus {
            outline: none;
        }

        &::placeholder {
            opacity: 0.5;
            color: $c-input-placeholder;
        }
    }
}
