.product-create {
    padding: 45px;

    &__wrapper {
        background: $c-white;
        border: 1px solid $c-layout-bg;
        border-radius: 8px;
        padding: 50px;
    }

    &__delete {
        display: flex;
        border-radius: 100px;
        border: 1px solid $c-danger;
        height: 36px;
        width: 130px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        
        p {
            margin-left: 12px;
        }
    }

    &__header {
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
            cursor: pointer;
        }

        &--create {
            justify-content: flex-end;
        }
    }

    &__status-btn {
        position: relative;
        width: 110px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 50px;
        background-color: $c-white;
        border: 1px solid rgba($c-black, 0.1);

        p {
            position: absolute;
            left: 17px;
        }

        svg {
            position: absolute;
            right: 0;
        }

        &--disabled {
            svg {
                left: 0;
            }
            p {
                left: inherit;
                right: 10px;
            }
        }
    }

    form {
    }

    &__gallery-wrapper {
        margin-top: 30px;
        margin-bottom: 15px;
    }

    &__gallery-action {
        display: flex;
        flex-wrap: wrap;
    }

    &__gallery-add {
        padding: 0;
        margin: 10px 10px 10px 0;
        width: 165px;
        height: 165px;
        border-radius: 10px;
        color: rgba($c-black, 0.3);
        background-color: $c-white;
        border: 1px dashed rgba($c-black, 0.2);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__gallery-image {
        position: relative;
        padding: 0;
        margin: 10px 10px 10px 0;
        width: 165px;
        height: 165px;
        border-radius: 10px;
        color: rgba($c-black, 0.3);
        background-color: $c-layout-bg;
        border: 1px dashed rgba($c-black, 0.2);
        cursor: pointer;
        overflow: hidden;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: linear-gradient(to top, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__gallery-video {
        position: relative;
        padding: 0;
        margin: 10px;
        width: 165px;
        height: 165px;
        border-radius: 10px;
        color: rgba($c-black, 0.3);
        border: 1px dashed rgba($c-black, 0.2);
        cursor: pointer;
        overflow: hidden;

        video {
            position: relative;
            object-fit: cover;
            z-index: 0;
        }
    }

    &__gallery-remove {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 18px;
        color: $c-white;
        z-index: 999999;
    }

    &__input-wrapper {
        margin: 20px 0;
        background-color: $c-white;
        width: 50%;

        &--variations {
            display: flex;
            // flex-direction: column;
            background-color: transparent;

            button {
                background-color: transparent;
            }
        }

    }

    &__input-s {
        width: 30%;
    }

    &__input-xs {
        width: 15%;
    }

    &__input-content {
        display: flex;
        justify-content: space-between;

        button {
            width: 150px;
            margin: 0 10px 0 0;
            .button__txt {
                font-size: 12px;
                margin: 0;
            }
        }
    }

    &__button-wrapper {
        margin-top: 35px;
    }

    &__inactive-popup {
        margin-top: 10px;

        &--msg {
            p {
                margin-bottom: 20px;
            }
        }
    }

    &__variation-toggle {
        position: relative;
        width: 40px;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 50px;
        background-color: $c-white;
        border: 1px solid rgba($c-black, 0.3);
        margin-left: 12px;
        margin-bottom: 10px;

        span {
            background-color: $c-valid;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            position: absolute;
            right: 4px;
        }
        
        &--disabled {
            span {
                background-color: rgba($c-black, 0.5);
                left: 4px;
                right: inherit;
            }
        }

    }

    &__variation-card {
        padding: 25px;
    }

    &__variation-header {
        width: 100%;
        padding: 10px;
        display: flex;
        justify-content: space-between;

        button {
            border-color: $c-dark-gold;

            .button__txt {
                color: $c-dark-gold;
            }

            &:hover {
                background-color: $c-dark-gold;

                .button__txt {
                    color: $c-white;
                }
            }

            &:disabled {
                opacity: 0.5;
                pointer-events: none;
                border-color: rgba($c-black, 0.5);

                .button__txt {
                    color: rgba($c-black, 0.5);
                }
            }
        }
    }

    &__variation-close {
        cursor: pointer;
    }

    &__variation-body {
        padding: 10px;
        width: 100%;
        max-height: 450px;
        min-width: 600px;
        overflow-y: scroll;
    }

    &__variation-input-header {
        display: flex;
        justify-content: space-between;

        svg {
            cursor: pointer;
            color: rgba($c-black, 0.5);
        }
    }

    &__variation-input {
        padding: 15px;
        margin-bottom: 15px;
        border-radius: 5px;
        border: 1px solid $c-border;
        background-color: $c-white;

        input {
            margin-top: 5px;
            border: 0;
            outline: none;
            font-size: 16px;

            &::placeholder {
                opacity: 0.5;
                color: $c-input-placeholder;
            }
        }
    }

    &__variation-add {
        padding: 0;
        margin: 10px 10px 10px 0;
        width: 100%;
        height: 50px;
        border-radius: 10px;
        color: rgba($c-black, 0.3);
        background-color: $c-white;
        border: 1px dashed rgba($c-black, 0.2);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            color: rgba($c-black, 0.3);
        }
    }

    &__variations-stock {
        display: flex;
        align-items: center;

        span {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &--minus {
            background-color: $c-secondary;
        }

        &--add {
            background-color: $c-dark-gold;
        }

        input {
            width: 100px;
            text-align: center;
            margin: 0 12px;
        }
    }

    &__variations-table {
        margin-top: 18px;
        padding-bottom: 20px;
        width: 65%;
        border-collapse: collapse;
        border-style: hidden;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);

        th {
            background-color: $c-secondary;

            &:first-child {
                border-radius: 6px 0px 0px 0px;
            }

            &:last-child {
                border-radius: 0px 6px 0px 0px;
            }
        }

        th,
        td {
            padding: 10px 15px;
            text-align: left;
            border: 1px solid $c-layout-bg;
            width: 25%;
        }

        input {
            padding: 10px;
            border-radius: 5px;
            border: 1px solid $c-border;
            outline: none;
        }
    }

    &__variation-simplified {
        display: flex;
        justify-content: space-between;
        background-color: rgba($c-black, 0.1);
        padding: 20px;

        & > :last-child {
            cursor: pointer;
        }

        &--text {
            display: flex;
            align-items: center;

            svg {
                margin-right: 10px;
            }
        }
    }

    &__variation-form {
        width: 65%;

        table {
            width: 100%;
            border-collapse: collapse;
            
            thead {
                background: #ECECEC;
                border-radius: 4px 4px 0px 0px;
                text-align: left;

                tr {

                    th {
                        
                        &:first-child {
                            width: 35%;
                            padding: 20px 0px 20px 30px;
                        }

                        &:nth-child(2) {
                            width: 45%;
                        }
                    }

                }
            }

            tbody {

                tr {
                    border: 1px solid $c-layout-bg;
                    border-radius: 4px;

                    td {

                        &:first-child {
                            width: 35%;
                            padding: 20px 20px 20px 30px;
                            vertical-align: top;
                        }

                        &:nth-child(2) {
                            width: 45%;
                            padding: 20px 0;
                        }

                        &:last-child {
                            width: 20%;
                            position: relative;
                        }

                        input {
                            padding: 12px 15px;
                            border-radius: 4px;
                            border: 1px solid #C4C4C4;
                        }
                    }
                }
            }
        }

        .variation__options {

            &--wrapper {
                width: 100%;
                border-radius: 4px;
                border: 1px solid #C4C4C4;
                display: flex;
                flex-wrap: wrap;
                position: relative;

                input {
                    border: none;

                    &:focus {
                        outline: none;
                    }
                }
            }

            &--add {
                width: 45px;
                height: 45px;
                border: 1px dashed #BBBBBB;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: auto;
                cursor: pointer;
            }

            &--remove {
                position: absolute;
                top: 50%;
                right: -14px;
                transform: translateY(-50%);
                width: 28px;
                height: 28px;
                background: $c-layout-bg;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }

            &-items {
                background: $c-layout-bg;
                border: 1px solid $c-layout-bg;
                border-radius: 100px;
                padding: 6px 10px;
                display: flex;
                margin: 10px 5px;

                &--remove {
                    display: flex;
                    align-items: center;
                    margin-left: 5px;
                }
            }

            &-limit {
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }

        &--btn {
            display: flex;
            justify-content: flex-end;
            margin-top: 25px;
        }
    }
}
