.business-info-board {
    position: relative;
    padding: 20px;
    max-width: 600px;
    min-height: 120px;
    margin: 10 px 0;
    border-radius: 8px;
    background-color: rgba($c-gold, 0.1);
    border: 1px solid rgba($c-dark-gold, 0.6);
    cursor: pointer;

    &--red {
        background-color: rgba($c-danger, 0.1);
        border: 1px solid rgba($c-danger, 0.6);
    }

    &--green {
        background-color: rgba($c-valid, 0.1);
        border: 1px solid rgba($c-valid, 0.6);
    }
    
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
            margin-bottom: 5px;
        }
    }

    &__icon {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
    }

    &__button {
        text-align: end;
        margin-top: 5px;

        button {
            border: none;
            padding: 7px 20px;
            background: $c-dark-gold;
            border-radius: 100px;
            cursor: pointer;
            color: $c-white;
        }
    }

    &__badge {
        position: absolute;
        top: -12px;
        right: -12px;
        width: 28px;
        height: 28px;
        border-radius: 50px;
        color: $c-white;
        background-color: $c-danger;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
