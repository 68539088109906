
.help {
    .container {
        flex-direction: row;
        align-items: stretch;
    }

    &__headline {
        margin: 35px 0;
    }

    &__card {
        width: 100%;
        min-height: 550px;
        margin: 35px;
        padding: 35px;
    }

    &__card-item {
        display: flex;
        align-items: center;
        background: $c-white;
        padding: 30px;
        margin: 20px 0;
        width: 70%;

        svg {
            margin-right: 25px;
        }

        &--text-center {
            text-align: center;
        }
    }
}
