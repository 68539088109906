.live-stream-table {
    width: 100%;
    margin-top: 10px;
    min-width: 860px;
    border: 1px solid rgba($c-layout-bg, 0.5);

    &__badge {
        position: relative;

        span {
            position: absolute;
            left: -5px;
            top: -5px;
            width: 22px;
            height: 22px;
            color: $c-white;
            font-size: 12px;
            border-radius: 50px;
            background-color: $c-valid;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }

    thead {
        background-color: $c-secondary;

        th {
            padding: 20px;
            border: 1px solid rgba($c-layout-bg, 0.5);
            text-align: left;

            &:first-child {
                width: 20%;
            }

            &:nth-child(2) {
                width: 30%;
                text-align: left;
            }
        }
    }

    tbody {
        background-color: $c-white;

        tr {
            cursor: pointer;
        }

        td {
            padding: 20px;
            border: 1px solid rgba($c-layout-bg, 0.5);
            text-align: left;

            &:first-child {
                width: 20%;
            }

            &:nth-child(2) {
                text-align: left;
                max-width: 300px;
                
                p {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
