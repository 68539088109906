.forgot-password {
    .container {
        flex-direction: row;
        align-items: stretch;
        text-align: center;
    }

    &__section {
        width: 100%;
        max-width: 400px;
        padding: 20px;

        img {
            width: 100%;
        }
    }

    &__divider {
        width: 1px;
        height: 90%;
        margin: 0 25px;
        background-color: $c-border;
    }

    &__headline, &__login, &__submit {
        margin: 20px 0;
    }

    &__card {
        width: 100%;
        min-height: 550px;
        margin: 35px;
        padding: 35px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}
