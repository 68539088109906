.product-table {
    width: 100%;
    min-width: 860px;
    border: 1px solid rgba($c-layout-bg, 0.5);

    &__header {
        display: flex;
        justify-content: space-between;
    }

    thead {
        background-color: $c-secondary;

        th {
            padding: 20px;
            border: 1px solid rgba($c-layout-bg, 0.5);
            text-align: center;

            &:first-child {
                width: 70px;
                border-right: 0;
                padding-right: 5px;
            }

            &:nth-child(2) {
                width: 25%;
                border-left: 0;
                padding-left: 5px;
                text-align: left;
            }

            &:last-child {
                width: 50px;
            }
        }
    }

    tbody {
        background-color: $c-white;

        tr {
            cursor: pointer;

            td {
                padding: 20px;
                border: 1px solid rgba($c-layout-bg, 0.5);
                text-align: center;

                &:first-child {
                    width: 70px;
                    border-right: 0;
                    padding-right: 5px;
                }

                &:nth-child(2) {
                    width: 25%;
                    border-left: 0;
                    padding-left: 5px;
                    text-align: left;
                }

                &:nth-child(6) {
                    text-align: left;
                }

                &:last-child {
                    width: 50px;
                }
            }
        }
    }

    &__selector {
        color: $c-layout-bg;
    }

    .all-disable {
        td {
            p {
                color:  rgba($c-black, 0.3) !important;
            }
        }
    }
}
