.filter {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;

    &__item {
        line-height: 30px;
        cursor: pointer;
        display: flex;
        align-items: center;
        
        span {
            height: 15px;
            border-right: 2px solid $c-secondary;
            margin: 0 15px;
        }
        
        &:last-child {
            span {
                border-right: 0;
            }
        }
    }
}