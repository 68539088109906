.tabs {
    display: flex;

    &__button {
        padding: 10px 25px;
        font-size: 18px;
        font-weight: 400;
        min-width: 150px;
        border: 0;
        cursor: pointer;
        border-bottom: 4px solid $c-layout-bg;
        background-color: transparent;

        p {
            color: rgba($c-black, 0.5);
        }

        &--active {
            border-bottom: 4px solid $c-black;

            p {
                color: $c-black;
            }
        }
    }
}
