.share-button {
    button {
        border: 0;
        cursor: pointer;
        background-color: transparent;
        display: flex;
        align-items: center;
    }

    &__icon {
        &--white {
            color: $c-white;
        }
    }
}

.share-modal {
    width: 400px;
    margin: 0 auto;
}

.share-card {
    position: relative;
    width: 400px;
    min-height: 200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: $s-shadow;

    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
        border: 0;
        cursor: pointer;
        background-color: transparent;
    }

    button {
        outline: none;
        margin: 10px 5px;
    }

    p {
        margin: 10px;
    }
}

.react-responsive-modal-modal {
    box-shadow: none;
    background-color: transparent;
}
