.empty-state {
    width: 100%;
    height: 100%;
    min-height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--msg {
        margin: 20px 0 30px 0;
    }
}