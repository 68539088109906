.verification-account {

    .container {
        flex-direction: row;
        align-items: stretch;
        text-align: center;
    }

    &__section {
        width: 100%;
        max-width: 400px;      
        padding: 20px;
    }

    &__divider {
        width: 1px;
        height: 90%;
        margin: 0 25px;
        background-color: $c-border;
    }

    &__title {
        margin-bottom: 25px;
        font-size: 30px;
        font-weight: 300;
        line-height: 1.2;
    }

    &__text {
        margin: 10px 0;
    }

    &__link {
        margin-top: 25px;
        a {
            text-decoration: underline;
        }
    }

    &__card {
        width: 100%;
        min-height: 550px;
        margin: 35px;
        padding: 35px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    &__token-field {
        width: 100% !important;
        max-width: 300px;
        margin: auto;

        input {
            height: 50px !important;
            width: 50px !important;
            margin: 10px;
            border: 0 !important;
            border-radius: 0 !important;
            background-color: $c-input-bg;
            font-family: 'Roboto', sans-serif !important;

            &:focus {
                caret-color: $c-black !important;
            }
        }
    }

    &__resend-button {
        &:disabled {
            opacity: 0.5;
        }
    }

    &__error {
        @extend .m-txt;
        @extend .m-txt--s;
        margin-bottom: 10px;
        color: $c-danger;
    }
}